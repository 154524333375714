import type { CreateAxiosDefaults, RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import https from 'https';
import process from 'process';
import { castEnv, getEnvUniversal } from '#root/src/utils/env-utils';

class RestApi {
  private readonly _axios;

  constructor(baseURL: string) {
    if (!baseURL) {
      throw new Error('Какой-то класс api не передал baseURL в родителя BaseApiClient');
    }

    const axiosHeaders: RawAxiosRequestHeaders = {
      Accept: 'application/json',
    };

    const axiosConfig: CreateAxiosDefaults = { baseURL, headers: axiosHeaders };
    // https - модуль ноды. Наверное можно как-то застаивть его работать на клиенте,
    // но пока я не смог этого сделать, поэтому завернул в трай коч
    try {
      // Когда сейчас запускаю в проде все равно использжуем self-signed стерификат.
      // TODO: вернуть проверку на прод
      if (typeof window === 'undefined') {
        axiosConfig.httpsAgent = new https.Agent({
          rejectUnauthorized: false,
        });
      }
    } catch (e) {
      // cdekConsoleError(e)
    }

    this._axios = axios.create(axiosConfig);
    this.setCache();
  }

  get axios() {
    return this._axios;
  }

  async setCache() {
    const checkCacheDisabled = castEnv(getEnvUniversal('VITE_REDIS_CACHE_DISABLED'), false);

    if ((process as any).browser || checkCacheDisabled) {
      return;
    }

    const { redisStorage } = await import('#root/src/utils/redis-storage/redis-storage');
    const { setupCache } = await import('axios-cache-interceptor');

    setupCache(this.axios, {
      // У нас огромное количество разных запросов, я не уверен, что мы хотим кешировать вообще все
      // поэтому пока делаем что то типа вайт листа. Там где хотим, чтобы запрос кешировался
      // добавляем в конфиг запроса { cache: {methods: ['yourMethod'] },
      methods: [],
      storage: redisStorage,
      ttl: (process.env.REDIS_TIMEOUT ? +process.env.REDIS_TIMEOUT : 600) * 1000,
    });
  }

  unAuthAxios() {
    return this.axios;
  }
}

export default RestApi;
