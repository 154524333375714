import type { ActiveHeadEntry, Head, Unhead } from '@unhead/schema';
import type IPage from '#root/src/types/dto/page';
import { getDefaultMetaData } from '#root/src/constants/default-meta-data';
import type { VueI18nTranslation } from 'vue-i18n';
import cloneDeep from 'lodash/cloneDeep';
import type ICdekComponent from '@/types/dto/cdekComponent';
import { ParamsTypeName } from '@/types/dto/cdekComponent';
import type { IWebsitePageData } from '@/types/dto/restWebsitePage';
import { findParamsInComponents } from './find-params-in-components';

interface MetaAsObj extends Head {
  index: number;
}

export const setTemplatedMetaTags = (
  t: VueI18nTranslation,
  components: ICdekComponent[],
  currentMeta: Head,
) => {
  let params = findParamsInComponents(components, ParamsTypeName.NewItem);

  if (!params) {
    params = findParamsInComponents(components, ParamsTypeName.PressNewItem);
  }

  const meta = currentMeta.meta || [];

  if (
    params?.__typename === ParamsTypeName.NewItem ||
    params?.__typename === ParamsTypeName.PressNewItem
  ) {
    const date = new Intl.DateTimeFormat('ru-RU').format(new Date(params.item.created));

    currentMeta.title = t(
      params?.__typename === ParamsTypeName.NewItem ? 'newsTitle' : 'pressNewsTitle',
      { title: params.item.title, date },
    );

    const newDescription = t(
      params?.__typename === ParamsTypeName.NewItem ? 'newsDescription' : 'pressNewsDescription',
      { title: params.item.title, date },
    );

    const descriptionIdx = meta.findIndex(({ name }) => name === 'description');

    if (descriptionIdx === -1) {
      meta.push({ name: 'description', content: newDescription });
    } else {
      meta[descriptionIdx].content = newDescription;
    }

    currentMeta.meta = meta;
    return currentMeta;
  }

  return currentMeta;
};

// Тайтл здесь - название страницы, все время он юзался как тайтл у страницы
// В metaTags тоже есть тайтл, Получается каша.
// А еще для некоторых страниц надо добавить кастомные мета теги.
// Надо их куда то запихать
export function prepareAdminPageMeta({ title, metaTags, scripts }: IPage) {
  const result: Head = {
    meta: [],
  };

  if (title) {
    result.title = title;
  }

  if (metaTags && metaTags.items && Array.isArray(metaTags.items)) {
    result.meta = metaTags.items.reduce(
      (acc: { name: string; content: string }[], { name, value }) => {
        if (name === 'title') {
          if (value) {
            result.title = value;
          }
        } else {
          acc.push({ name, content: value });
        }

        return acc;
      },
      [],
    );
  }

  if (metaTags && !metaTags.items && Object.keys(metaTags).length > 0) {
    result.meta = Object.keys(metaTags).map((name) => {
      return {
        name,
        content: (metaTags as Record<string, string>)[name],
      };
    });
  }

  if (scripts && scripts.length && Array.isArray(scripts)) {
    result.script = scripts.map(({ type, text }) => {
      return {
        type,
        innerHTML: JSON.parse(text),
        hid: 'ldjson',
      };
    });
  }

  return result;
}

export const prepareRestApiMetaData = (page: IWebsitePageData) => {
  const result: Head = {
    meta: [],
  };

  if (page.title) {
    result.title = page.title;
  }

  if (page.metaTags.h1) {
    result.meta?.push({ name: 'h1', content: page.metaTags.h1 });
  }

  if (page.metaTags.description) {
    result.meta?.push({ name: 'description', content: page.metaTags.description });
  }

  return result;
};

export function prepareAllMeta(newMetaData: Head, defaultMeta: Head) {
  try {
    const result = cloneDeep(defaultMeta);

    const { title: newTitle, meta: newMeta } = cloneDeep(newMetaData);

    if (newTitle) {
      result.title = newTitle;
    }

    const defaultMetaAsObj = result?.meta?.reduce((obj: Record<string, MetaAsObj>, curr, i) => {
      const name = curr?.name;

      if (name) {
        obj[name as string] = { ...curr, index: i };
      }

      return obj;
    }, {});

    if (newMeta && defaultMetaAsObj) {
      newMeta.forEach((el) => {
        const newEl = { ...el, 'data-n-head': 'ssr' };

        if ((newEl.name as string)?.startsWith('og:')) {
          newEl.property = newEl.name;
          delete newEl.name;
        }

        const name = el.name as string;

        if (name && defaultMetaAsObj[name] && result.meta) {
          result.meta[defaultMetaAsObj[name].index] = newEl;
          return;
        }

        result?.meta?.push(newEl);
      });
    }

    return result;
  } catch (e) {
    return defaultMeta;
  }
}

export function updateHead(
  t: VueI18nTranslation,
  activeHead?: Unhead<any>,
  activeEntry?: ActiveHeadEntry<Head>,
  metaData?: Head,
): ActiveHeadEntry<Head> | undefined {
  if (!activeHead) {
    return;
  }

  if (activeEntry) {
    activeEntry.dispose();
  }

  let newMetaData: Head = getDefaultMetaData(t);

  if (metaData) {
    newMetaData = prepareAllMeta(metaData, newMetaData) as Head;
  }

  return activeHead.push(newMetaData);
}

export function rewriteServerMetaData(
  t: VueI18nTranslation,
  activeHead?: Unhead<any>,
  activeEntry?: ActiveHeadEntry<Head>,
  metaData?: Head,
) {
  const defaultMeta = document.querySelectorAll('[data-n-head="ssr"]');
  const arrMeta = [...defaultMeta];
  arrMeta.forEach((n) => n.remove());

  return updateHead(t, activeHead, activeEntry, metaData);
}
