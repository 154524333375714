import client from '@/api-clients/cabinetApi/client';
import type { App } from 'vue';
import type { CdekStore } from '@/types/store';

export default {
  install(app: App, store: CdekStore) {
    const apiClient = client(store);
    app.config.globalProperties.$apiClient = apiClient;
    store.$apiClient = apiClient;
    app.provide('apiClient', apiClient);
  },
};
