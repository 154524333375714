import { getEnvUniversal } from '#root/src/utils/env-utils';

export function baseUrlGuard(apiName: string, serverEnvName: string, clientPrefixEnvName: string) {
  if (typeof window === 'undefined') {
    if (!getEnvUniversal('VITE_HTTP_ENDPOINT') && !getEnvUniversal(serverEnvName)) {
      throw new Error(
        `Не могу создать ${apiName} на сервере, задайте ${serverEnvName} или VITE_HTTP_ENDPOINT`,
      );
    }

    if (
      !getEnvUniversal(serverEnvName) &&
      getEnvUniversal('VITE_HTTP_ENDPOINT') &&
      !getEnvUniversal(clientPrefixEnvName)
    ) {
      throw new Error(`Не могу создать ${apiName} на сервере, задайте ${clientPrefixEnvName}`);
    }
  } else if (!getEnvUniversal(clientPrefixEnvName)) {
    throw new Error(`Не могу создать ${apiName} на клиенте, задайте ${clientPrefixEnvName}`);
  }
}

export function getBaseApiUrl(serverUrl: string, clientPrefix: string) {
  const httpEndpoint = getEnvUniversal('VITE_HTTP_ENDPOINT');

  if (httpEndpoint) {
    // dev override
    return `${httpEndpoint}${clientPrefix}`;
  }

  if (typeof window === 'undefined') {
    // server part
    return serverUrl;
  }

  // client part
  return clientPrefix;
}
