import { Multitone, getInstanceFactory } from '@cdek-ui-kit/vue';

import axios from 'axios';
import https from 'https';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getEnvUniversal } from '#root/src/utils/env-utils';

export const getExpressApiUrl = () => {
  const serverUrl = `http://${getEnvUniversal('VITE_FRONT_HOST')}:${getEnvUniversal(
    'VITE_FRONT_PORT',
  )}`;
  return typeof window === 'undefined' ? serverUrl : '';
};

export class ExpressApi extends Multitone {
  private _axios: AxiosInstance;

  constructor() {
    super();

    const baseURL = getExpressApiUrl();

    const config: AxiosRequestConfig = {
      baseURL,
    };

    if (typeof window === 'undefined') {
      config.httpsAgent = new https.Agent({
        rejectUnauthorized: false,
      });
    }

    this._axios = axios.create(config);
  }

  get(url: string) {
    return this._axios.get(url);
  }
}

export const getExpressApi = getInstanceFactory<ExpressApi>(ExpressApi);
