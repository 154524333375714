import type IPage from '#root/src/types/dto/page';
import RestApi from '#root/src/api-clients/base-api-client';
import type { DefaultRequestData } from '#root/src/types/dto/defaultRequestData';
import type { InvestmentSendEmailData } from '#root/src/types/dto/investmentSendEmailData';
import { RedisError } from '#root/src/utils/redis-storage/RedisError';
import { getEnvUniversal } from '#root/src/utils/env-utils';
import { getBaseApiUrl, baseUrlGuard } from '#root/src/api-clients/getBaseApiUrl';
import type { CdekDefaultCity } from '@/types/dto/city';

export default class GraphqlApiClient extends RestApi {
  constructor() {
    baseUrlGuard('GraphqlApiClient', 'VITE_SERVER_SITE_API_URL', 'VITE_CLIENT_SITE_API_PREFIX');

    const postfix = getEnvUniversal('VITE_SITE_GRAPHQL_POSTFIX');

    if (!postfix) {
      throw new Error('Не могу создать GraphqlApiClient, задайте VITE_SITE_GRAPHQL_POSTFIX');
    }

    const serverUrl = `${getEnvUniversal('VITE_SERVER_SITE_API_URL')}${postfix}`;
    const clientUrl = `${getEnvUniversal('VITE_CLIENT_SITE_API_PREFIX')}${postfix}`;

    super(getBaseApiUrl(serverUrl, clientUrl));
  }

  // TODO: вынести в интерцепторы
  async unAuthCachedPost(href: string, body: any, config: any = {}) {
    try {
      config.cache = { methods: ['post'] };
      return (await this.unAuthAxios().post(href, body, config)).data;
    } catch (e) {
      if (e instanceof RedisError) {
        config.cache = false;

        return (await this.unAuthAxios().post(href, body, config)).data;
      }

      throw e;
    }
  }

  async websiteOfficeMap(code: string, locale: string): Promise<unknown> {
    const { websiteOfficeMapGql } = await import('#root/src/gql/website-office-map.gql');
    const body = {
      query: websiteOfficeMapGql,
      variables: {
        code,
        filter: {
          locale,
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websiteCountriesPaginated(locale: string): Promise<unknown> {
    const { websiteCountriesPaginatedGql } = await import(
      '#root/src/gql/website-countries-paginated.gql'
    );
    const body = {
      query: websiteCountriesPaginatedGql,
      variables: {
        filter: {
          locale,
        },
      },
    };

    return this.unAuthCachedPost('', body);
  }

  async websiteCitiesPaginated(
    { locale }: DefaultRequestData,
    name: string,
    country_code: string,
  ): Promise<{
    date: {
      websiteCitiesPaginated: {
        items: CdekDefaultCity[];
      };
    };
  }> {
    const { websiteCitiesPaginatedGql } = await import(
      '#root/src/gql/website-cities-paginated.gql'
    );
    const body = {
      query: websiteCitiesPaginatedGql,
      variables: {
        filter: {
          locale,
          name,
          country_code,
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websitePage(url: string, locale: string, cookieABTestMap?: string | null): Promise<IPage> {
    const { websitePageTextQuery } = await import('#root/src/gql/website-page.gql');

    const body = {
      query: websitePageTextQuery,
      variables: {
        url,
        locale,
      },
    };

    const postOptions: any = {};

    if (cookieABTestMap) {
      postOptions.headers = { Cookie: `ab_test_map=${cookieABTestMap}` };
      postOptions.cache = cookieABTestMap.includes(url) ? false : { methods: ['post'] };
    }

    const res = await this.unAuthCachedPost('', body, postOptions);

    return res.data.page;
  }

  async websiteOfficesPaginated({ locale }: DefaultRequestData, cityCode: number) {
    const { websiteOfficesPaginatedGql } = await import(
      '#root/src/gql/website-offices-paginated.gql'
    );
    const body = {
      query: websiteOfficesPaginatedGql,
      variables: {
        limit: 1,
        filter: {
          locale,
          cityCode,
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websiteOfficesCoordsPaginated(variables: unknown) {
    const { websiteOfficesCoordsPaginatedGql } = await import(
      '#root/src/gql/website-offices-coords-paginated.gql'
    );
    const body = {
      query: websiteOfficesCoordsPaginatedGql,
      variables,
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websitePressNewsPaginated({ locale }: DefaultRequestData, limit: number, offset: number) {
    const { websitePressNewsPaginated } = await import(
      '#root/src/gql/website-press-news-paginated'
    );
    const body = {
      query: websitePressNewsPaginated,
      variables: {
        limit,
        offset,
        filter: {
          locale,
        },
        sort: {
          field: 'sorted',
          dir: 'DESC',
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websiteVideosPaginated(
    { locale }: DefaultRequestData,
    sort: {
      field: string;
      dir: string;
    },
    offset: number,
    limit: number,
  ) {
    const { websiteVideosPaginatedGql } = await import(
      '#root/src/gql/website-videos-paginated.gql'
    );
    const body = {
      query: websiteVideosPaginatedGql,
      variables: {
        filter: {
          locale,
        },
        limit,
        offset,
        sort,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async sendCallback(form: Record<string, string>) {
    const { sendCallbackGql } = await import('#root/src/gql/send-callback.gql');
    const body = {
      query: sendCallbackGql,
      variables: {
        input: {
          ...form,
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async questionsAdd(input: unknown) {
    const { questionsAddGql } = await import('#root/src/gql/questions-add.gql');
    const body = {
      query: questionsAddGql,
      variables: {
        input,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async notificationSenderSms(phone: string, message: string) {
    const { notificationSenderSmsGql } = await import('#root/src/gql/notification-sender-sms.gql');
    const body = {
      query: notificationSenderSmsGql,
      variables: {
        input: {
          phone,
          message,
        },
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async sendEmail(variables: unknown) {
    const { sendEmailGql } = await import('#root/src/gql/send-email.gql');
    const body = {
      query: sendEmailGql,
      variables,
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async InvestmentCalculatorResult(
    { locale }: DefaultRequestData,
    pdate: string,
    sdate: string,
    size: string,
  ) {
    const { investmentCalculatorResult } = await import(
      '#root/src/gql/investment-calculator-result.gql'
    );
    const body = {
      query: investmentCalculatorResult,
      variables: {
        locale,
        pdate,
        sdate,
        size,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async InvestmentSendEmail(input: InvestmentSendEmailData) {
    const { investmentSendEmail } = await import('#root/src/gql/investment-send-email.gql');
    const body = {
      query: investmentSendEmail,
      variables: {
        input,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async getInfoPayment(variables: unknown) {
    const { getInfoPaymentGql } = await import('#root/src/gql/payment/get-info-payment.gql');
    const body = {
      query: getInfoPaymentGql,
      variables,
      context: {
        enabledGlobalError: false,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async getOrderInfo(variables: unknown) {
    const { getOrderInfoGql } = await import('#root/src/gql/payment/get-order-info.gql');
    const body = {
      query: getOrderInfoGql,
      variables,
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async onlinePayment(input: unknown) {
    const { onlinePaymentGql } = await import('#root/src/gql/payment/online-payment.gql');
    const body = {
      query: onlinePaymentGql,
      variables: {
        input,
      },
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async payment(variables: unknown) {
    const { paymentGql } = await import('#root/src/gql/payment/payment.gql');
    const body = {
      query: paymentGql,
      variables,
    };

    return (await this.unAuthAxios().post('', body)).data;
  }

  async websiteOfficeByCode(variables: unknown) {
    const { websiteOfficeByCodeGql } = await import('#root/src/gql/website-office-by-code.gql');
    const body = {
      query: websiteOfficeByCodeGql,
      variables,
    };

    return (await this.unAuthAxios().post('', body)).data.data;
  }
}
