// noinspection NpmUsedModulesInstalled
import type { RawAxiosRequestHeaders } from 'axios';
import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { cdekConsoleInfo } from '@/utils/console-wrapper';
import { getMappedLocale } from '@/utils/map.locale.utils';
import { getEnvUniversal } from '@/utils/env-utils';
import { getBaseApiUrl, baseUrlGuard } from '@/api-clients/getBaseApiUrl';
import type MainState from '@/types/store';
import type { Store } from 'vuex';
import type { CdekCity } from '@/types/dto/city';
import { getWebsiteId } from '@/utils/international.utils';
import type { LkWebsiteId } from '@/env';
import type { AdvertAgreement } from '@/types/dto/advertAgreement';
import type { GetOrderNotifications } from '@/types/dto/cabinet';
import { useKeycloak } from '@/composables/use-keyclock';
import type { Order } from '@/types/order';

const USER_ME = '/user/me/';
const USER_PROFILE_EMAIL = '/user/profile/email';
const USER_PROFILE_EMAIL_CONFIRM = '/user/profile/email/confirm';
const USER_ADVERT_AGREEMENT = '/user/advert-agreement';

const REFRESH = '/auth/refresh-token/';

const HELLO = '/hello/';

const TRACING_TRACK_INFO = '/tracing/trackInfo/';

const ENDPOINT_CITIES_AUTOCOMPLETE = '/cities/autocomplete/';
const ENDPOINT_PACKAGING_TYPES = '/packaging-types/';
const ENDPOINT_ESTIMATE_V2 = '/estimateV2/';
const ENDPOINT_GET_TARIFF_INFO = '/getTariffInfo/';
const ENDPOINT_CURRENCIES = '/currencies/';
const ENDPOINT_STREET_TIME_CITY = '/cities/time/';
const ENDPOINT_LOYALTY_OFFERS = '/loyalty/offers/';
const ENDPOINT_LOYALTY_POINTS = '/loyalty/points/';
const ENDPOINT_COD_PAYMENT_STATUS = '/cashOnDelivery/paymentStatus/';
const ENDPOINT_COD_PAYMENT_STATUS_BY_ORDER = '/cashOnDelivery/paymentStatus/{orderNumber}/';
const ENDPOINT_COD_PAYMENT_OUT = '/cashOnDelivery/paymentOut/';
const ENDPOINT_COD_PAYMENT_INIT = '/cashOnDelivery/paymentInit/';
const ENDPOINT_LOYALTY_ORDER_PRECHECK = '/loyalty/orderPrecheck/';
const ENDPOINT_USER_ORDER_NOTIFICATIONS = '/user/order-notifications/';
const ENDPOINT_GET_CITY_BY_ID = '/cities/{code}/';
const ENDPOINT_FISCAL_DOCUMENTS = '/fiscalDocuments/documentsList/';

const ENDPOINT_RATING_PRODUCTS_REVIEWS_INFO = '/rating-products/reviews-info/';
const ENDPOINT_RATING_PRODUCTS_SEND_POPUP_SHOWED = '/rating-products/send-popup-showed/';
const ENDPOINT_RATING_PRODUCTS_REVIEW = '/rating-products/review/';

const GIFTS_PRODUCTS = '/gifts/products/';
const GIFTS_PRODUCTS_BURN = '/gifts/products/burn/';
const GIFT_PRODUCTS_BY_UUID = '/gifts/products/{uuid}/';
const GIFTS_ADD_TO_FAVORITE = '/gifts/favourites/{uuid}/';
const GIFTS_REMOVE_FROM_FAVORITE = '/gifts/favourites/{uuid}/';
const GIFTS_FAVORITES = '/gifts/favourites/';
const GIFTS_PRECHECK = '/gifts/precheck/';
const GIFTS_PURCHASE = '/gifts/purchase/';
const GIFTS_ORDERS_DELETE = '/gifts/orders/{uuid}/';

const TOP_CITIES = '/cities/top/';

class CabinetApi {
  baseURL: string;

  _store: Store<MainState>;

  locale: string;

  websiteId: string;

  /**
   * @param {string} apiHost
   * @param {string} apiPrefix
   * @param {object} store
   *
   * @constructor
   */
  constructor(store: Store<MainState>) {
    baseUrlGuard('CabinetApi', 'VITE_SERVER_CABINET_API_URL', 'VITE_CLIENT_CABINET_API_PREFIX');

    if (!store) {
      throw new Error('Не могу создать CabinetApi, передайте store в конструктор');
    }

    this.baseURL = getBaseApiUrl(
      getEnvUniversal('VITE_SERVER_CABINET_API_URL'),
      getEnvUniversal('VITE_CLIENT_CABINET_API_PREFIX'),
    );

    this._store = store;

    this.locale = getMappedLocale(this.store.getters?.currentLocaleISO) ?? 'ru/';
    this.websiteId = getWebsiteId();
  }

  get store() {
    return this._store;
  }

  setLocale(lang: Exclude<LkWebsiteId, 'by'>) {
    this.locale = getMappedLocale(lang);
  }

  async getSsoToken() {
    // TODO: разобраться с типами
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!this.store.state.auth.isSsoAuth) {
      return null;
    }

    const { updateToken, getToken } = useKeycloak();

    try {
      await updateToken();
    } catch (error) {
      throw new Error('TOKEN_EXPIRED');
    }

    return getToken();
  }

  async accessToken() {
    const ssoToken = await this.getSsoToken();

    if (ssoToken) {
      return [ssoToken];
    }

    const impersonationAccessToken = this.impersonationAccessToken();

    if (impersonationAccessToken) {
      return [impersonationAccessToken, true];
    }

    const accessToken = this.store.getters['keycloak/accessToken'];
    const refreshToken = this.store.getters['keycloak/refreshToken'];
    const accessTokenExpiredAt = this.store.getters['keycloak/expiredAt'];
    const refreshTokenExpiredAt = this.store.getters['keycloak/refreshExpiredAt'];
    const nowTS = Math.round(new Date().getTime() / 1000);
    let accessTokenExpiredAtTS = Math.round(new Date(accessTokenExpiredAt).getTime() / 1000);
    let refreshTokenExpiredAtTS = Math.round(new Date(refreshTokenExpiredAt).getTime() / 1000);

    if (accessTokenExpiredAtTS === 0) {
      accessTokenExpiredAtTS = 2 ** 31;
    }

    if (refreshTokenExpiredAtTS === 0) {
      refreshTokenExpiredAtTS = 2 ** 31;
    }

    const accessTokenReducedExpiredTS = accessTokenExpiredAtTS - 10;
    const refreshTokenReducedExpiredTS = refreshTokenExpiredAtTS - 10;

    if (accessToken && accessTokenReducedExpiredTS > nowTS) {
      return [accessToken];
    }

    if (!refreshToken) {
      throw new Error('REFRESH_TOKEN_EMPTY');
    }

    if (refreshTokenReducedExpiredTS < nowTS) {
      throw new Error('REFRESH_TOKEN_EXPIRED');
    }

    cdekConsoleInfo('Access token is expired, refreshing...');

    return this.refresh(refreshToken)
      .then((response) => {
        /**
         * @type {KeycloakResponse}
         */
        const keycloakResponse = JSON.parse(response.data.data);

        this.store.commit('keycloak/setKeycloak', keycloakResponse);

        return [this.store.getters['keycloak/accessToken']];
      })
      .catch(() => {
        throw new Error('REFRESH_TOKEN_PENDING');
      });
  }

  impersonationAccessToken() {
    const obj = this.store.getters['auth/getImpersonation'];
    const token = obj?._t;

    if (!token) {
      return null;
    }

    const isImpersonationTokenExpired = this.store.getters['auth/isImpersonationTokenExpired'];
    if (isImpersonationTokenExpired()) {
      throw new Error('TOKEN_EXPIRED');
    }

    return token;
  }

  /**
   * Returns authenticated axios instance with auto refreshing access token.
   *
   * @returns {Promise<AxiosInstance>}
   */
  async authAxios() {
    return this.accessToken()
      .then(([accessToken, asUser = false]) => {
        if (!accessToken) {
          throw new Error('"accessToken" is required for auth requests.');
        }

        const headers: RawAxiosRequestHeaders = {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          'X-Site-Code': this.websiteId,
          'X-User-Lang': this.locale,
        };

        if (asUser) {
          headers['X-Auth-As-User'] = 'true/';
        }

        return axios.create({
          baseURL: this.baseURL,
          headers,
        });
      })
      .catch((error) => {
        switch (error.message) {
          case 'REFRESH_TOKEN_EMPTY':
          case 'REFRESH_TOKEN_EXPIRED':
            cdekConsoleInfo('Refresh token empty or expired.');

            this.store.dispatch('auth/removeImpersonation', true);

            break;

          case 'REFRESH_TOKEN_PENDING':
            cdekConsoleInfo('Refresh token not empty, but auth gateway is inaccessible.');

            break;

          case 'TOKEN_EXPIRED':
            cdekConsoleInfo('Token empty or expired.');
            this.store.dispatch('auth/removeImpersonation', true);
            break;

          default:
            break;
        }

        return axios.create({
          baseURL: this.baseURL,
        });
      });
  }

  /**
   * Returns unauthenticated axios instance.
   *
   * @returns {Promise<AxiosInstance>}
   */
  // eslint-disable-next-line class-methods-use-this
  async unauthAxios(url = '') {
    return axios.create({
      baseURL: url || this.baseURL,
      headers: {
        Accept: 'application/json',
        'X-Site-Code': this.websiteId,
        'X-User-Lang': this.locale,
      },
    });
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async hello() {
    return (await this.unauthAxios()).get(HELLO);
  }

  /**
   * @returns {Promise<AxiosResponse<any>>}
   */
  async userMe() {
    return (await this.authAxios()).get(USER_ME);
  }

  async setEmail(email: string | null) {
    return (await this.authAxios()).post(USER_PROFILE_EMAIL, {
      email,
    });
  }

  async confirmEmail(email: string, token: string) {
    return (await this.authAxios()).put(USER_PROFILE_EMAIL_CONFIRM, {
      email,
      token,
    });
  }

  async getUserAdvertAgreement(): Promise<AdvertAgreement> {
    const {
      data: { data },
    } = await (await this.authAxios()).get(USER_ADVERT_AGREEMENT);

    return data;
  }

  async saveUserAdvertAgreement(agreed: boolean) {
    return (await this.authAxios()).put(USER_ADVERT_AGREEMENT, { agreed });
  }

  async getOrderNotifications(): Promise<GetOrderNotifications> {
    const res = await (await this.authAxios()).get(ENDPOINT_USER_ORDER_NOTIFICATIONS);
    return res.data.data;
  }

  async getPaymentInfo(order_numbers: unknown) {
    const res = await (
      await this.authAxios()
    ).get(ENDPOINT_COD_PAYMENT_STATUS, {
      params: { order_numbers },
    });
    return res.data.data;
  }

  async getCashOnDeliveryInfo(orderNumber: string) {
    const url = ENDPOINT_COD_PAYMENT_STATUS_BY_ORDER.replace('{orderNumber}', orderNumber);
    const res = await (await this.unauthAxios()).get(url);

    return res.data;
  }

  /**
   * @param {string} order_number
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async paymentInit(order_number: string) {
    const res = await (
      await this.authAxios()
    ).post(ENDPOINT_COD_PAYMENT_INIT, {
      order_number,
    });

    return res.data.data;
  }

  /**
   * @param {string} order_number
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async paymentOut(order_number: string) {
    const res = await (
      await this.authAxios()
    ).post(ENDPOINT_COD_PAYMENT_OUT, {
      order_number,
    });

    return res.data.data;
  }

  async trackInfo(orderNumber: string): Promise<{
    data: {
      data: Order;
    };
  }> {
    return (await this.authAxios()).get(`${TRACING_TRACK_INFO}${orderNumber}`);
  }

  async trackDetailInfo(orderNumber: string) {
    return (await this.authAxios()).get(`${TRACING_TRACK_INFO}${orderNumber}/details`);
  }

  async getLoyaltyPrecheckInfo(orderNumber: number, coupons: number, points: number) {
    const { data } = await (
      await this.unauthAxios()
    ).post(ENDPOINT_LOYALTY_ORDER_PRECHECK, { orderNumber, coupons, points });

    return data;
  }

  async getLoyaltyOffers(isAuth = false) {
    const axiosInstance = await (isAuth ? this.authAxios() : this.unauthAxios());

    const res = await axiosInstance.get(ENDPOINT_LOYALTY_OFFERS);
    return res.data.data;
  }

  async getLoyaltyPoints() {
    const { data } = await (await this.authAxios()).get(ENDPOINT_LOYALTY_POINTS);

    return data.data;
  }

  // order
  /**
   * @param str {string}
   * @param limit {number}
   * @returns {Promise<any>}
   */
  async citiesAutocomplete(str = '', limit = 10): Promise<CdekCity[]> {
    const params = {
      str,
      limit,
    };
    const res = await (await this.unauthAxios()).get(ENDPOINT_CITIES_AUTOCOMPLETE, { params });

    return res.data.data;
  }

  /**
   * @returns {Promise<any>}
   */
  async getPackagingTypes() {
    const res = await (await this.unauthAxios()).get(ENDPOINT_PACKAGING_TYPES);
    return res.data;
  }

  /**
   * @param form {{currency: Object, origin: Object, destination: Object, parcels: Object[]}}
   * @returns {Promise<void>}
   */
  async getOrderTariffV2(form: unknown) {
    const res = await (await this.unauthAxios()).post(ENDPOINT_ESTIMATE_V2, form);
    return sortBy(res.data.data, ['placing']);
  }

  /**
   * @param {Boolean} isAuth
   * @param {Object} form
   * @param {Boolean} form.withoutAdditionalServices
   * @param {String} form.payerType
   * @param {String} form.currencyMark
   * @param {String} form.senderCityId
   * @param {String} form.receiverCityId
   * @param {String} form.serviceId
   * @param {String} form.mode
   * @param {Object[]} form.packages
   * @param {Object[]} form.additionalServices
   * @returns {Promise<void>}
   */
  async getTariffInfo(isAuth: boolean, form: unknown) {
    const axiosInstance = await (isAuth ? this.authAxios() : this.unauthAxios());
    const res = await axiosInstance.post(ENDPOINT_GET_TARIFF_INFO, form);
    return res.data.data;
  }

  /**
   * @param cityCode
   * @returns {Promise<*>}
   */
  async getCurrenciesByCityCode(cityCode = 14) {
    const params = { cityCode };
    const res = await (await this.unauthAxios()).get(ENDPOINT_CURRENCIES, { params });
    return res.data.data;
  }

  /**
   * @param cityCode
   * @returns {Promise<any>}
   */
  async getCurrentTimeCity(cityCode: string) {
    const params = { cityCode };
    const res = await (await this.unauthAxios()).get(ENDPOINT_STREET_TIME_CITY, { params });
    return res.data;
  }

  /**
   * @param {string} refreshToken
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  async refresh(refreshToken: string) {
    return (await this.unauthAxios(getEnvUniversal('VITE_CLIENT_SITE_API_PREFIX'))).post(
      `${REFRESH}`,
      {
        refreshToken,
      },
    );
  }

  async getCityById(id: string) {
    const url = ENDPOINT_GET_CITY_BY_ID.replace('{code}', id);
    const res = await (await this.unauthAxios()).get(url);
    return res.data.data;
  }

  async ratingProductsReview(form: unknown) {
    const res = await (await this.authAxios()).post(ENDPOINT_RATING_PRODUCTS_REVIEW, form);
    return res.data.data;
  }

  async ratingProductsSendPopupShowed() {
    const res = await (await this.authAxios()).post(ENDPOINT_RATING_PRODUCTS_SEND_POPUP_SHOWED);
    return res.data.data;
  }

  /**
   * @returns {Promise<{countPopup: String, availableProducts: Array, currentProductId: String}>}
   */
  async ratingProductsReviewsInfo() {
    const res = await (await this.authAxios()).get(ENDPOINT_RATING_PRODUCTS_REVIEWS_INFO);
    return res.data.data;
  }

  async giftsProducts() {
    const url = `${GIFTS_PRODUCTS}?available=true`;

    const res = await (await this.authAxios()).get(url);
    return res.data.data;
  }

  async giftsProductsBurn() {
    const res = await (await this.authAxios()).get(GIFTS_PRODUCTS_BURN);
    return res.data.data;
  }

  async giftsProductsByUuid(uuid: string) {
    const url = GIFT_PRODUCTS_BY_UUID.replace('{uuid}', uuid);

    const res = await (await this.authAxios()).get(url);
    return res.data.data;
  }

  async giftsAddToFavorite(uuid: string) {
    const url = GIFTS_ADD_TO_FAVORITE.replace('{uuid}', uuid);
    const res = await (await this.authAxios()).put(url);
    return res.data.data;
  }

  async giftsRemoveFromFavorite(uuid: string) {
    const url = GIFTS_REMOVE_FROM_FAVORITE.replace('{uuid}', uuid);
    const res = await (await this.authAxios()).delete(url);
    return res.data.data;
  }

  async giftsFavorite() {
    const res = await (await this.authAxios()).get(GIFTS_FAVORITES);
    return res.data.data;
  }

  async giftsPrecheck(productUuid: string) {
    const res = await (await this.authAxios()).post(GIFTS_PRECHECK, { productUuid });
    return res.data.data;
  }

  async giftsPurchase(productUuid: string) {
    const res = await (await this.authAxios()).post(GIFTS_PURCHASE, { productUuid });
    return res.data.data;
  }

  async giftsOrdersDelete(uuid: string) {
    const url = GIFTS_ORDERS_DELETE.replace('{uuid}', uuid);

    const res = await (await this.authAxios()).delete(url);
    return res.data.data;
  }

  async getTopCities(): Promise<CdekCity[]> {
    return (await (await this.unauthAxios()).get(TOP_CITIES)).data.data;
  }

  async fiscalDocuments(search: string) {
    const params = {
      search,
    };

    const res = await (await this.authAxios()).get(ENDPOINT_FISCAL_DOCUMENTS, { params });
    return res.data;
  }
}

export default CabinetApi;
